







































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import AddAudioAd from '@/components/Monetize/AddAudioAd.vue'
import ThAdList from '@/components/Common/ThAdList.vue'
import debounce from 'lodash.debounce'
import { selectionType } from '@/components/Monetize/monetize'
import { reactive } from '@vue/composition-api'
import { useGetAllTracks } from '@/hooks/audio'
import get from 'lodash.get'
@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    AddAudioAd,
    ThAdList,
  },
  setup() {
    const adsParams: any = reactive({
      paginate: true,
      limit: 10,
      offset: 0,
      category: 'AD',
      fromYoutube: false,
      query: '',
    })

    const { data: adsData, isFetching: adsLoading } = useGetAllTracks(adsParams)
    return {
      adsParams,
      adsData,
      adsLoading,
    }
  },
})
export default class ViewMonetize extends Vue {
  adsParams!: any
  adsData!: any
  adsLoading!: boolean

  @Action showAudioAddPlayer!: any

  selectedCollectionType: string = selectionType.AUDIO_ADS
  adSearchValue = ''
  currentAudio!: any
  currentUrl!: string
  timer: number = 0
  pageNo = 1
  increment = 10

  async mounted() {
    this.selectedCollectionType = selectionType.AUDIO_ADS
    await this.mapRouteParamsToData()
    this.adsParams.offset = (this.pageNo - 1) * this.increment
  }

  get selectionType() {
    return selectionType
  }
  get adsList() {
    return get(this.adsData, 'data', [])
  }
  get adsCount() {
    return get(this.adsData, 'count', 0)
  }

  get pageCount() {
    return Math.ceil(get(this.adsData, 'count', 0) / 10)
  }

  async onAudioSearchValueChanged() {
    this.adsParams.query = this.adSearchValue
  }

  debouncedSearch = debounce(() => {
    this.onAudioSearchValueChanged()
  }, 600)

  @Watch('$route')
  onRouteChanged() {
    this.mapRouteParamsToData()
  }
  setSelectedCollectionType(value: string) {
    this.selectedCollectionType = value
  }
  async mapRouteParamsToData() {
    const channelType = this.$route.params.channel
    const channelId = this.$route.params.id
    if (channelType === 'audioads') {
      this.setSelectedCollectionType('AUDIO_ADS')
    }
    if (channelType === 'coming-soon') {
      this.setSelectedCollectionType('COMING_SOON')
    }
  }
  handleAddAudio() {
    this.$store.commit('setCurrentModal', 'AudioAd')
  }
  clearSearch() {
    this.adSearchValue = ''
    this.adsParams.query = this.adSearchValue
  }

  @Watch('pageNo')
  watchPageNo() {
    this.adsParams.offset = (this.pageNo - 1) * this.increment
  }

  async OnPageNumberChanged(page: number) {
    if (this.timer) {
      window.clearTimeout(this.timer)
    }
    this.timer = window.setTimeout(async () => {
      if (page <= this.pageCount && page > 0) {
        this.pageNo = page
      }
    }, 600)
  }

  async next() {
    if (this.pageNo + 1 <= this.pageCount) {
      this.pageNo += 1
    }
  }

  async prev() {
    if (this.pageNo > 1) {
      this.pageNo -= 1
    }
  }
}
