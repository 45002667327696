var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c(
            "div",
            { staticClass: "limiter scrollbar mb5" },
            [
              _c(
                "TheTitle",
                { attrs: { icon: "monetization_on", title: "Monetize" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      attrs: { slot: "left" },
                      slot: "left",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-end mr4" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "fw3 f5 dib link glow pt3 pb1 pointer no-outline",
                              class:
                                _vm.selectedCollectionType ===
                                _vm.selectionType.AUDIO_ADS
                                  ? "light-gray bb b--white bw1"
                                  : "silver bb b--adori-transparent bw1",
                              attrs: { to: "/monetize/audioads" },
                            },
                            [_vm._v("Audio ads")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "f7 bg-adori-very-light-gray br3 white ml1",
                              staticStyle: {
                                padding: "2px 5px",
                                "margin-bottom": "6px",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.adsList.length > 0
                                      ? _vm.adsList.length
                                      : 0
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "flex justify-between mb3 mt4" }, [
                _c("div", { staticClass: "flex relative" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.adSearchValue,
                        expression: "adSearchValue",
                      },
                    ],
                    ref: "_searchAudios",
                    staticClass:
                      "bg-adori-gray bw0 br2 pl3 pr5 pv2 f6 white searchInp",
                    attrs: { autofocus: "", placeholder: "Search Ads" },
                    domProps: { value: _vm.adSearchValue },
                    on: {
                      keyup: function ($event) {
                        return _vm.debouncedSearch()
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.adSearchValue = $event.target.value
                      },
                    },
                  }),
                  _vm.adSearchValue.length === 0
                    ? _c(
                        "i",
                        {
                          staticClass:
                            "material-icons silver absolute icon-position",
                        },
                        [_vm._v("search")]
                      )
                    : _c(
                        "i",
                        {
                          staticClass:
                            "material-icons silver absolute pointer dim icon-position",
                          on: { click: _vm.clearSearch },
                        },
                        [_vm._v("close")]
                      ),
                ]),
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("BaseButtonRed", {
                      staticClass: "ph4 h2",
                      attrs: {
                        text: "Create Audio Ad",
                        onClick: _vm.handleAddAudio,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.selectedCollectionType === _vm.selectionType.AUDIO_ADS
                ? _c("div", [
                    _vm.adsLoading
                      ? _c(
                          "div",
                          _vm._l(new Array(11), function (_, index) {
                            return _c("div", {
                              key: index,
                              staticClass:
                                "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center skeleton cus-size mb2",
                            })
                          }),
                          0
                        )
                      : _c("div", [
                          _vm.adsCount === 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "bg-adori-light-gray br2 ba b--adori-gray light-gray vh-75 flex justify-center items-center column",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../assets/monetize/file.png"),
                                      alt: "",
                                      width: "150",
                                      height: "150",
                                    },
                                  }),
                                  _c("div", { staticClass: "shadow" }),
                                  _c("div", [
                                    _vm._v("Oops no ads to display! Add one"),
                                  ]),
                                  _c("BaseButtonRed", {
                                    staticClass: "ph4 mt3 mr1 h2",
                                    attrs: {
                                      text: "+ Add Audio Ad",
                                      onClick: _vm.handleAddAudio,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm.adsList.length > 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-wrap justify-between ph2 pv3 link light-gray bg-adori-light-gray bb b--adori-gray br2 relative",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-20 flex justify-center",
                                        },
                                        [_vm._v("Title")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-20 flex justify-center",
                                        },
                                        [_vm._v("Duration")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-20 flex justify-center",
                                        },
                                        [_vm._v("Preview")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-20 flex justify-center",
                                        },
                                        [_vm._v("View Audio")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-10 flex justify-center",
                                        },
                                        [_vm._v("Edit")]
                                      ),
                                    ]
                                  ),
                                  _vm._l(_vm.adsList, function (ad, index) {
                                    return _c("ThAdList", {
                                      key: index,
                                      attrs: { ad: ad },
                                    })
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-end pv3 ph4 bt bg-adori-light-gray b--adori-gray bw1",
                                    },
                                    [
                                      _c("BaseButtonRed", {
                                        staticClass: "mr3 youtube-link",
                                        attrs: {
                                          text: "Prev",
                                          onClick: _vm.prev,
                                          disabled: _vm.pageNo === 1,
                                        },
                                      }),
                                      _c("input", {
                                        staticClass:
                                          "bg-adori-light-gray adori-gray br2 w-38",
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.pageNo },
                                        on: {
                                          input: function ($event) {
                                            _vm.OnPageNumberChanged(
                                              parseInt($event.target.value)
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "pa2 silver" },
                                        [_vm._v("/ " + _vm._s(_vm.pageCount))]
                                      ),
                                      _c("BaseButtonRed", {
                                        staticClass: "ml2 youtube-link",
                                        attrs: {
                                          text: "Next",
                                          onClick: _vm.next,
                                          disabled:
                                            _vm.pageNo === _vm.pageCount,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-wrap justify-center ph1 pv3 link light-gray bg-adori-light-gray bb b--adori-gray br2 relative f4",
                                },
                                [
                                  _vm._v(
                                    "\n            No Ads Found\n          "
                                  ),
                                ]
                              ),
                        ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }